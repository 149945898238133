<template>
    <div>
        <el-card shadow="never">
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>型号列表</span>
                </div>
            </template>
            <div class="content">
                <div class="content-header">
                    <el-row :gutter="5">
                        <el-col :span="12"   style="text-align:left;">
                            <div class="searchblock">
                                
                                <el-select v-model="brandid" placeholder="请选择品牌" filterable style="margin-right:10px;" clearable size="small">
                                    <el-option v-for="brand in brands"
                                        :key="brand.ID"
                                        :label="brand.Brand"
                                        :value="brand.ID"></el-option>
                                </el-select>
                                <!--
                                <el-select v-model="categoryid" placeholder="按品类搜索" filterable style="margin-right:10px;" clearable>
                                    <el-option v-for="category in categorys"
                                        :key="category.ID"
                                        :label="category.Category"
                                        :value="category.ID"></el-option>
                                </el-select>-->
                                <!--
                                <el-select v-model="categoryid" class="input-size" placeholder="请选择品类" style="margin-right:10px;" clearable filterable>
                                    <el-option
                                        v-for="category in categorys"
                                        :key="category.ID"
                                        :label="category.Category"
                                        :value="category.ID"
                                        >
                                        <span class="sel_cate_name" :class="'depth_' + category.Depth">{{
                                        category.Category
                                        }}</span>
                                    </el-option>
                                </el-select>-->
                                <el-cascader :options="option" 
                                            v-model="categoryid" 
                                            placeholder="请选择品类" 
                                            clearable
                                            style="margin-right:10px;" 
                                            :show-all-levels="false"
                                            :props="props"
                                            size="small"
                                            ></el-cascader>
                                <el-input v-model="keyword" placeholder="型号名称" prefix-icon="el-icon-search" class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                                <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down" size="small">搜索</el-button>
                                <el-button type="success" @click="add" size="small">添加</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="content-main">
                <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                                v-loading="load" 
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中..."
                                size="small"
                                :default-sort = "{prop: 'AddDTime', order: 'descending'}">
                    <el-table-column label="型号名称" min-width="200" prop="Model"></el-table-column>
                    <el-table-column label="所属品类" width="250" prop="Category" ></el-table-column>
                    <el-table-column label="所属品牌" width="250" prop="Brand" ></el-table-column>
                    <el-table-column label="创建时间" width="200" prop="AddDTime" :formatter="Getdate" sortable></el-table-column>
                    <el-table-column label="状态" width="200">
                        <template #default="scope">
                            <span v-if="scope.row.Status == 0"><span style="color:red">停用</span></span>
                            <span v-if="scope.row.Status == 1">启用</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="270">
                        <template #default="scope">
                            
                            <el-button v-if="scope.row.Status == 0" type="text" size="medium" @click="Enable(scope.row.ID)" style="margin-right:20px;">启用</el-button>
                            <el-button v-if="scope.row.Status == 1" type="text" size="medium" @click="Disable(scope.row.ID)" style="margin-right:20px;">停用</el-button>
                            <el-button type="text" size="medium" @click="Edit(scope.row.ID)" :disabled="scope.row.Status == 0?false:true" style="margin-right:20px;">
                                编辑</el-button>
                            <el-button type="text" size="medium" @click="Del(scope.row.ID)" :disabled="scope.row.Status == 0?false:true">删除</el-button>
                        </template>
                    </el-table-column>

                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
            </div>
        </el-card>
    </div>
    <el-dialog v-model="editor" title="编辑型号信息" width="30%">
        <el-form :model="modelinfo" label-position="left" :rules="rule" ref="modelinfo">
            <el-form-item label="型号名称" label-width="100px" prop="modelname">
                <el-input v-model="modelinfo.modelname"></el-input>
            </el-form-item>
            <el-form-item label="所属品牌" label-width="100px" prop="brand">
                <el-select v-model="modelinfo.brand" filterable placeholder="请选择">
                    <el-option v-for="brand in brands" 
                        :key="brand.ID" 
                        :value="brand.ID" 
                        :label="brand.Brand"
                    ></el-option>
                </el-select>
            </el-form-item>
            
            <el-form-item label="所属品类" label-width="100px" prop="category">
                <!--
                <el-select v-model="modelinfo.category" filterable placeholder="请选择">
                    <el-option
                        v-for="category in categorys"
                        :key="category.ID"
                        :label="category.Category"
                        :value="category.ID"
                        >
                        <span class="sel_cate_name" :class="'depth_' + category.Depth">{{
                            category.Category
                            }}</span>
                        </el-option>
                </el-select>-->
                <el-cascader :options="option" 
                            v-model="modelinfo.category" 
                            placeholder="请选择品类" 
                            clearable
                            style="margin-right:10px;" 
                            :show-all-levels="false"
                            :props="props"

                            ></el-cascader>
                
            </el-form-item>
            <el-form-item label="备注"  label-width="100px">
                <el-input type="textarea" rows="4" v-model="modelinfo.remark" placeholder="请选择"></el-input>
            </el-form-item>
            <!--
            <el-form-item :label="[modelinfo.status == 1?'启用':'停用']" label-width="100px">
                
                <el-switch v-model="modelinfo.status"
                            inactive-value="0"
                            active-value="1"
                            @change="ChangeLabel">
                </el-switch>
                
            </el-form-item>-->
        </el-form>
        <template #footer>
            <span class="dialog-footer">
            <el-button @click="CloseDialog('modelinfo')">取 消</el-button>
            <el-button type="primary" @click="submitform('modelinfo')">确 定</el-button>
            </span>
        </template>
    </el-dialog>
</template>
<style scoped>
.searchblock{
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;

}
</style>
<script>
import constant from "@/constant"
import { ElMessage } from 'element-plus'
export default {
    data(){
        return {
            curpage:1,
            pagesize:10,
            totalcount:"",
            props:{
                label:'label',
                value:'value',
                disabled:'Disabled'
            },
            selectCateData:[],
            option:[],
            brandid:'',
            categoryid:'',
            keyword:'',
            brands:'',
            categorys:[],
            tabledata:[],
            statuslabel:'停用',
            editor: false,
            modelinfo:{
                modelname:'',
                remark:'',
                id:'',
                status: '0',
                brand:'',
                category:'',
                value:'',
            },
            rule:{
                modelname:[{
                    required: true, message: '请输入型号名称',trigger: 'blur'
                }],
                brand:[{
                    required: true, message: '请选择品牌',trigger: 'blur'
                }],
                category:[{
                    required: true, message: '请选择品类',trigger: 'blur'
                }]
            }
        }
    },
    methods:{
        CloseDialog(formName){
            this.editor = false;
            this.$refs[formName].resetFields();
        },
        add(){
            this.categoryid = '';
            this.modelinfo.modelname = '';
            this.modelinfo.id = '';
            this.modelinfo.remark = '';
            this.modelinfo.brand = '';
            this.modelinfo.category = '';
            this.editor = true;
        },
        submitform(formName){
            this.$refs[formName].validate((valid)=>{
                if(valid){
                    console.log(this.modelinfo);
                    //return false;
                    
                    this.axios.post(constant.save_model_url,this.modelinfo,{
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'}
                    }).then((response)=>{
                        console.log(response.data);
                        const cur_obj = this;
                        if(response.data == "OK"){
                            cur_obj.editor =false;
                            ElMessage.success({
                                type:'success',
                                message:'操作成功',
                                duration:1000,
                                onClose:function(){
                                    
                                    cur_obj.$router.go(0);
                                }
                            });
                        }else{
                            ElMessage.error({
                                type:'error',
                                message:'操作失败',
                                duration:1500,
                            });
                        }
                    })
                }else{
                    return false;
                }
            });
        },
        ChangeLabel(){
            if(this.modelinfo.status == "0")
                this.statuslabel = '停用';
            if(this.modelinfo.status == "1")
                this.statuslabel = '启用';
        },
        Getdate(row, column){
            var cur_date = row[column.property];
            console.log(cur_date);
            return  formatDate(cur_date);
            function formatDate(date){
                var datetime = new Date(date*1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                return year + "-" + month + "-" + day;
            }
        },
        Edit(id){
            if(!id)
                return false;
            console.log(id);
            this.axios.get(constant.get_model_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                this.editor = true;
                this.modelinfo.modelname = response.data.Model;
                this.modelinfo.id = response.data.ID;
                this.modelinfo.remark = response.data.Remark;
                this.modelinfo.status = response.data.Status;
                this.modelinfo.brand = response.data.fkBrandID;
                this.modelinfo.category = response.data.value;
                
            });
        },
        Enable(id){
            this.axios.get(constant.enable_model_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response);
                if(response.data == "OK"){
                    this.$router.go(0);
                }else{
                    ElMessage.error({
                        type:'error',
                        message: '操作失败'
                    });
                }
            });
            return false;
        },
        Disable(id){
            this.axios.get(constant.disable_model_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response);
                if(response.data == "OK"){
                    this.$router.go(0);
                }else{
                    ElMessage.error({
                        type:'error',
                        message: '操作失败'
                    });
                }
            });
            return false;
        },
        Del(id){
            if(!confirm("此操作将删除该型号, 是否继续?"))
                return false;
            this.axios.get(constant.del_model_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                const cur_obj = this;
                if(response.data == "OK"){
                    ElMessage.success({
                        type:'success',
                        message:'删除成功',
                        duration:1000,
                        onClose:function(){
                            cur_obj.$router.go(0);
                        }
                    });
                }else if(response.data == "ISENABLE"){
                    ElMessage.error({
                        type:'error',
                        message:'删除失败，请停用该型号，并确认该型号下没有产品再进行删除操作'
                    });
                }
            });
        },
        init(){
            console.log(this.brandid);
            console.log(this.categoryid);
            
            this.axios.get(constant.get_model_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    type:1,
                    keyword: this.keyword,
                    brandid: this.brandid,
                    curpage:this.curpage,
                    pagesize: this.pagesize,
                    categoryid: JSON.stringify(this.categoryid)
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data.msg == 'ok'){
                    this.tabledata = response.data.list;
                    this.curpage = response.data.curpage;
                    this.totalcount = response.data.totalcount;
                }else
                    this.tabledata = response.data;
                });
        },
        handleSizeChange(val){
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.init();
        },
        
    },
    created:function(){
        this.axios.get(constant.get_model_url,{
            headers:{
                'Content-Type': 'application/json'
            },
            params:{
                type:1,
                curpage:this.curpage,
                pagesize: this.pagesize,
            }
        }).then((response)=>{
            console.log(response.data);
            if(response.data.msg == 'ok'){
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage;
                this.totalcount = response.data.totalcount;
            }else
                this.tabledata = response.data;
        });
        this.axios.get(constant.get_brand_url,{
            headers:{
                'Content-Type': 'application/json'
            },
            params:{
                status:1
            }
        }).then((response)=>{
            this.brands = response.data;
        });
        
        this.axios.get(constant.get_cascader_url,{
            headers:{
                'Content-Type': 'application/json'
            },
        }).then((response)=>{
            console.log(response.data);
            this.option = response.data;
        });
        /*
        this.axios.get(constant.get_tree_url,{
            headers: {
                "Content-Type": "application/json",
            },
            params:{
                status:1
            }
        }).then((response)=>{
            console.log(response.data);
            this.categorys = response.data;
        });*/
    }
}
</script>